const colors = {
  // Theme UI colour names
  text: "#3b3b3b",
  background: "#f9f9f9",
  primary: "#07c",
  secondary: "#05a",
  accent: "#07c", // colour for show date
  muted: "#737373", // colour for show location

  // Additional colour names
  bgAccent: "rgba(0,0,0,0.1)",
  bgOpaque: "rgba(255,255,255,0.9)",
}

// colors.bgGradient = `linear-gradient(150deg, ${colors.bgAccent} 10%, ${
//   colors.text
// } 60%, ${colors.text} 100%)`

colors.bgGradient = 'linear-gradient(150deg, #3f87a6, #ebf8e1, #f69d3c)'

export default colors
